import React from 'react';

import axios from 'axios';
import parametres from '../../config/parameters';
import { Card,CardContent } from '@material-ui/core';
// import {AppContext} from '../App';
import CanvasJSReact from '../../assets/canvasjs.react';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function ChartParService (){
	const [dataGeo,setDataGeo]=React.useState([]);
	// const [dataNonGeo,setDataNonGeo]=React.useState([]);
	React.useEffect(()=>{
		fetchStatistique();
	},[])
	const fetchStatistique = async ()=>{
		const res = await axios.get(parametres.API_URL+'/statistiques/services',{
            headers:{'authorization':'Bearer '+localStorage.getItem('token')},
		}); 
		let statGeo = res.data.geo.map(s=>({label:s.service_gestion,y:parseInt(s.nbre),indexLabel:s.nbre}));
		// let statNonGeo = res.data.nonGeo.map(s=>({label:s.service_gestion,y:parseInt(s.nbre),indexLabel:s.nbre}));
		setDataGeo(statGeo);   
		// setDataNonGeo(statNonGeo);   
        // setServices(res.data);
	}
	const options = {
		animationEnabled: true,
		exportEnabled: true,
		theme: "light2", //"light1", "dark1", "dark2"
		title:{
			text: "Géolocalisation par service de gestion",
			fontSize:20,
			margin:2,
			horizontalAlign: "left",
		},
		data: [{
			type: "column", //change type to bar, line, area, pie, etc
			//indexLabel: "{y}", //Shows y value on all Data Points
			indexLabelFontColor: "#5A5757",
			indexLabelPlacement: "outside",
			legendText: "Géolocalisé",
			showInLegend: true, 
			dataPoints: dataGeo
		}/*,{
			type: "column", //change type to bar, line, area, pie, etc
			//indexLabel: "{y}", //Shows y value on all Data Points
			indexLabelFontColor: "#5A5757",
			indexLabelPlacement: "outside",
			legendText: "Non Géolocalisé",
			showInLegend: true, 
			dataPoints: dataNonGeo
		}*/
	]
	}
	return (
		<Card>
			<CardContent>
				<CanvasJSChart options = {options} />
			</CardContent>
		</Card>
	)
 }         
 