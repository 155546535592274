import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,FormControl,Button} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {MTableToolbar} from 'material-table';

// import moment from 'moment';
import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';

// import validate from '../../validate';
import parametres from '../../config/parameters';
import util from '../../util';
import IconMaterialTable from '../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function Liste(){
    const classes = useStyles();
    // eslint-disable-next-line
    const [dossiers,setDossiers] = React.useState([]);
    // const [query,setQuery] = React.useState({})
    const refTableOperation = React.useRef(null);
    const appContext = React.useContext(AppContext);
    const history = useHistory();
    const colonnes = [
        { 
            title: 'Date reception', field: 'date_maj',type:'date',editable:'never',
            render: rowData => moment(rowData.date_maj).format('DD/MM/YYYY HH:mm:ss')

        },
        { title: 'N° Dossier', field: 'data_envoye.numero_dossier', editable: 'never' },
        { title: 'Raison', field: 'data_envoye.raison_sociale',editable:'never' },
        { title: 'RCCM', field: 'data_envoye.rccm',editable:'never' },
        { title: 'NIFP', field: 'data_envoye.nif',editable:'never' },
        { title: 'Date Immat', field: 'data_envoye.date_immatriculation',editable:'never',
            render: rowData => moment(rowData.data_envoye.date_immatriculation).format('DD/MM/YYYY HH:mm:ss')
        },
        // { title: 'Msg.Retour', field: 'data_envoye.messages',editable:'never', 
        //     render: rowData => JSON.stringify(rowData.data_envoye.messages)
        // },
        // { title: 'Message', field: 'reponse_envoi',editable:'never'},
    ];
    

    const fetchData = async (query)=>{
        try {
            const res = await axios.get(parametres.API_URL+'/apip/nifs-envoyes',{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
                params:{...query}
            });
            setDossiers(res.data);
            return {
                data:res.data.liste,
                page:query.page,
                totalCount:parseInt(res.data.count)
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    return (
        <Container className={classes.root}>
                <Typography variant="h4" color="inherit" align="center" className={classes.title}>
                        Liste des NIFPs envoyés
                </Typography>
                <MaterialTable
                    title=""
                    icons={IconMaterialTable}
                    columns={colonnes}
                    data={fetchData}
                    tableRef={refTableOperation}
                    actions={[
                        {
                            icon:'assignment',
                            tooltip:'Details',
                            onClick:(e,rowData)=>history.push('apip-nifs-envoyes/'+rowData.id)
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize:20,
                        pageSizeOptions:[20,50,100],
                        debounceInterval:1000,
                    }}
                    components={{
                        Toolbar: props => (
                          <div>
                            <MTableToolbar {...props} />
                            <FormControl className={classes.formControl}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<RefreshIcon />}
                                    onClick={()=>refTableOperation.current.onQueryChange()}
                                >
                                    Actualiser
                                </Button>
                            </FormControl>
                          </div>
                        ),
                      }}      
                />
        </Container>
    )
};

