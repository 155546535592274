import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChartParService from './ChartParService';
import ChartParEquipe from './ChartParEquipe';
import ChartEvolution from './ChartEvolution';

const useStyles = makeStyles(theme => ({
	card: {
	  padding: theme.spacing(2),
	},
  }));
export default function Home (){
	const classes = useStyles();

	return (
		<Container>
			<Grid container spacing={2}>
				<Grid item xs={6} className={classes.card}>
					<ChartParService />
				</Grid>
				<Grid item xs={6} className={classes.card}>
					<ChartParEquipe />
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} className={classes.card}>
					<ChartEvolution />
				</Grid>
			</Grid>
		</Container>
	)
 }         
 