import React from "react";
import { Switch, Route,Redirect } from "react-router-dom";

import Home from './Components/Dashboard/Home';
import Login from "./Components/Auth/Login";
import Logout from "./Components/Auth/Logout";

import ListeUser from "./Components/User/Liste";
import ListeOperationEnAttente from "./Components/Operation/ListeEnAttente";
import ListeOperationValidees from "./Components/Operation/ListeValidees";
import ListeNonGeolocalises from "./Components/Operation/ListeNonGeolocalises";
import ListeGeolocalises from "./Components/Operation/ListeGeolocalises";
import ListeOperationRejetees from "./Components/Operation/ListeRejetees";
import ModificationOperationValidees from "./Components/Operation/ModificationOperationValidees";
import SyncManuelSigfig from "./Components/Operation/SyncManuelSigfig";
import FicheContribuable from "./Components/Contribuable/Fiche";
import Carte from "./Components/Carte/Carte";
import DGDTransfertPro from "./Components/DGD/TransfertPro";
import DGDTransfertIO from "./Components/DGD/TransfertIO";
import DGDTransfertDetail from "./Components/DGD/Detail";
import APIPDossiersRecus from "./Components/APIP/DossiersRecus";
import APIPDetailDossier from "./Components/APIP/DetailDossier";
import APIPNifsEnvoyes from "./Components/APIP/NifEnvoyes";
import APIPDetailNifs from "./Components/APIP/DetailNifs";

export const AppRoute = () => (
    <Switch>
        <Route path="/contribuable/:nif" component={FicheContribuable} /> 
        <Route path="/users" exact component={ListeUser} />
        <Route path="/operations-en-attente" exact component={ListeOperationEnAttente} />
        <Route path="/operations-validees" exact component={ListeOperationValidees} />
        <Route path="/operations-rejetees" exact component={ListeOperationRejetees} />
        <Route path="/contribuables-geolocalises" exact component={ListeGeolocalises} />
        <Route path="/contribuables-non-geolocalises" exact component={ListeNonGeolocalises} />
        <Route path="/modification-operations-validees" exact component={ModificationOperationValidees} />
        <Route path="/sync-manuel-contribuable" exact component={SyncManuelSigfig} />
        <Route path="/dgd-transfert-pro" exact component={DGDTransfertPro} />
        <Route path="/dgd-transfert-io" exact component={DGDTransfertIO} />
        <Route path="/dgd-transfert/:id" exact component={DGDTransfertDetail} />
        <Route path="/apip-dossiers-recus" exact component={APIPDossiersRecus} />
        <Route path="/apip-dossiers-recus/:id" exact component={APIPDetailDossier} />
        <Route path="/apip-nifs-envoyes" exact component={APIPNifsEnvoyes} />
        <Route path="/apip-nifs-envoyes/:id" exact component={APIPDetailNifs} />
        <Route path="/carte" exact component={Carte} /> 
        <Route path="/logout" exact component={Logout} />
        <Route path="/" exact component={Home} />
        {/* <Redirect to="/sync-manuel-contribuable"/>  */}
        <Redirect to="/"/> 
    </Switch>
)

export const AppRouteAuth = () => (
    <Switch>
        <Route path="/login" exact component={Login} />
        <Redirect to="/login"/>
    </Switch>
)