import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,Paper,
    TextField,Grid,Button } from '@material-ui/core';

import axios from 'axios';

import { AppContext } from '../../App';


import validate from '../../validate';
import constraintUser from '../../Constraints/User';
import util from '../../util';
import parametres from '../../config/parameters';

const useStyles = makeStyles(theme => ({
    root: {
        width: '400px',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    textField: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function Login(){
    const classes = useStyles();
    const [user,setUser]=React.useState({username:'',password:'',errors:{}})
    const appContext = React.useContext(AppContext);

    const handleChange = (e)=> {
        const u = {...user,[[e.target.name]]:e.target.value};
        setUser(u);
    }
    const keyPressed = (event)=> {
        if (event.key === "Enter") {
          login();
        }
      }
    const login = async ()=>{
        console.log(user);
        const errors = validate(user,constraintUser.login);
        if(errors){
            setUser({...user,errors});
            return;
        }
        try {
            const res = await axios.post(parametres.API_URL+'/login',user);
            appContext.storeToken(res.data.token);
            appContext.addMessage('Bienvenue sur la plateforme de géolocalisation','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    return (
        <Container className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h4" color="inherit" align="center" className={classes.title}>
                        CONNEXION
                </Typography>
                <form action="" onKeyPress={keyPressed}>
                    <TextField
                        name="username"
                        label="username"
                        className={classes.textField}
                        value={user.username}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        error={user.errors['username']?true:false}
                        helperText={user.errors ? util.getMessageConstraintError(user.errors['username']):''}
                    />
                    <TextField
                        name="password"
                        label="Mot de passe"
                        className={classes.textField}
                        value={user.password}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        type="password"
                        error={user.errors['password']?true:false}
                        helperText={user.errors ? util.getMessageConstraintError(user.errors['password']):''}
                    />
                </form>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        <Button variant="contained" color="primary" className={classes.button} 
                            onClick={login}
                        >
                            CONNEXION
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
};