import { CsvBuilder } from 'filefy';

const errorHandlerAjv = (errors) => {
    //localize(errors);
    return errors.map(e=>{
        return `${e.dataPath} ${e.message}`; 
    })
}
const findIdById = (values,id) =>{
    if(!Array.isArray(values)){
        if(values.prototype.hasOwnProperty('id')) return values.id
        return null;
    }
    const value = values.find(v=>v.id===id);
    return value||null;
}
const getContentError = (error) => {
    if(error.response) return error.response.data.message
    return error.message
}

const getMessageConstraintError = (errors)=>{
    return errors ? errors.join('-'): '';
}

const centrePoints = (points) => {   
    if (points.length === 0){
    return [];
    } 

    let num_coords = points.length;

    let X = 0.0;
    let Y = 0.0;
    let Z = 0.0;
    points.forEach(p=>{
        let lat = p.latitude * Math.PI / 180;
        let lon = p.longitude * Math.PI / 180;

        let a = Math.cos(lat) * Math.cos(lon);
        let b = Math.cos(lat) * Math.sin(lon);
        let c = Math.sin(lat);

        X += a;
        Y += b;
        Z += c;
    })

    X /= num_coords;
    Y /= num_coords;
    Z /= num_coords;

    let lon = Math.atan2(Y, X);
    let hyp = Math.sqrt(X * X + Y * Y);
    let lat = Math.atan2(Z, hyp);

    let newX = (lat * 180 / Math.PI);
    let newY = (lon * 180 / Math.PI);

    return [newX, newY];
}

export const byString = (o, s) => {
    if (!s) {
      return;
    }
  
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var x = a[i];
      if (o && x in o) {
        o = o[x];
      } else {
        return;
      }
    }
    return o;
  };

const getFieldValue = (rowData, columnDef, lookup = true) => {
    let value = (typeof rowData[columnDef.field] !== 'undefined' ? rowData[columnDef.field] : byString(rowData, columnDef.field));
    if (columnDef.lookup && lookup) {
      value = columnDef.lookup[value];
    }

    return value;
  }

const exportCsv = (filename,cols,values) =>{
    const columns = cols
      .filter(columnDef => {
        return !columnDef.hidden && columnDef.field && columnDef.export !== false;
      })
      .sort((a, b) => (a.tableData.columnOrder > b.tableData.columnOrder) ? 1 : -1);
    const data = values.map(rowData =>
      columns.map(columnDef => {
        return getFieldValue(rowData, columnDef);
      })
    );

    const builder = new CsvBuilder(filename);
    builder.setDelimeter(";")
    .setColumns(columns.map(columnDef => columnDef.title))
    .addRows(data)
    .exportFile();
}
export default {
    errorHandlerAjv,
    findIdById,
    getContentError,
    getMessageConstraintError,
    centrePoints,
    exportCsv
}