import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,TextField} from '@material-ui/core';

// import moment from 'moment';
import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';

// import validate from '../../validate';
import parametres from '../../config/parameters';
import util from '../../util';
import IconMaterialTable from '../IconMaterialTable';
import SelectRoles from './SelectRoles';

const useStyles = makeStyles(theme => ({
    root: {
        width: '80%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))

const roles = ["USER","VISITEUR","BIG","SUPERVISEUR","ADMIN","APIP","DGD","IO","PRO"];
export default function Liste(){
    const classes = useStyles();
    const [users,setUsers] = React.useState([]);
   
    const appContext = React.useContext(AppContext);
    const colonnes = [
        { title: 'Username', field: 'username', editable: 'onAdd' },
        { title: 'Nom', field: 'nom' },
        { title: 'Prenom', field: 'prenom' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email'},
        { title: 'Password', 
            field: 'password',
            editComponent: props => (
            <TextField
                type="password"
                placeholder="Password"
                name={props.name}
                value={props.value||''}
                onChange={e => props.onChange(e.target.value)}
                InputProps={{style: {fontSize: 13}}}
          />)
        },
        {title:'Roles',field:'roles',
            render:rowData=>rowData.roles ? rowData.roles.join(','):"",
            editComponent: props=>(
                <SelectRoles roles={roles} rolesSelected={props.value} onChange={props.onChange} />
            ),
            lookup:{ADMIN:'ADMIN',USER:'USER',SUPERVISEUR:'SUPERVISEUR',VISITEUR:'VISITEUR',BIG:'BIG'}

        }
    ];
    
    React.useEffect(()=>{
        async function fetchUsers(){
            try {
                const res = await axios.get(parametres.API_URL+'/users',{
                    headers:{'authorization':'Bearer '+localStorage.getItem('token')}
                });
                setUsers(res.data);
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
            }
        };
        fetchUsers();
        // eslint-disable-next-line
    },[]);
    const rowUpdate = async (newData,oldData)=>{
        try {
            const res = await axios.put(parametres.API_URL+'/users/'+oldData.id,
                {...newData},
                {headers:{'authorization':'Bearer '+localStorage.getItem('token')}}
            );
            const user = res.data;
            // eslint-disable-next-line
            const us = users.map(u=>u.id==user.id?user:u);
            setUsers(us);
            appContext.addMessage('Modification effectuée avec succès','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }

    const rowAdd = async (newData)=>{
        try {
            const res = await axios.post(parametres.API_URL+'/users/',
                {...newData},
                {headers:{'authorization':'Bearer '+localStorage.getItem('token')}}
            );
            const user = res.data;
            setUsers([...users,user]);
            appContext.addMessage('Ajout effectuée avec succès','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }

    return (
        <Container className={classes.root}>
                <Typography variant="h4" color="inherit" align="center" className={classes.title}>
                        Liste des utilisateurs
                </Typography>
                <MaterialTable
                    title=""
                    icons={IconMaterialTable}
                    columns={colonnes}
                    data={users}
                    editable={{
                        onRowUpdate:rowUpdate,
                        onRowAdd:rowAdd
                    }}
                />
        </Container>
    )
};

