import React from 'react';
import jwt from 'jsonwebtoken';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme,makeStyles } from '@material-ui/core/styles';

import {AppRoute,AppRouteAuth } from './AppRoute';
import MenuLateral from './Components/MenuLateral';
import Message from './Components/Message/Message';
import ConfirmationDialog from './Components/ConfirmationDialog';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#64b5f6',
          },
          secondary: {
            main:'#b71c1c',
          },    
    },
    typography: {
        fontFamily: [
          'Century Gothic',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      }, 
  });
  
  const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
      }
}))

export const AppContext = React.createContext();
export default function App() {
    const classes = useStyles();
    const [messages,setMessages] = React.useState([])
    const [user,setUser] = React.useState(null)
    const [confirmationMessage,setConfirmationMessage] = React.useState(null);
    const [openConfirmationMessage,setOpenConfirmationMessage] = React.useState(false)

    React.useEffect(()=>{
        const token = localStorage.getItem('token');
        if(token) storeToken(token);
    },[])

    React.useEffect(()=>{
        if(confirmationMessage) setOpenConfirmationMessage(true);
        else setOpenConfirmationMessage(false);
    },[confirmationMessage])

    const addMessage = (content,type)=>{
        setMessages([...messages,{content,type}]);
    }
    const storeToken = (token) => {
        const user = jwt.decode(token);
        localStorage.setItem('token', token);
        localStorage.setItem('role',JSON.stringify(user.roles));
        setUser(user);
    }
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        setUser(null);
    }
    const isAuthorized = (roles)=>{
        let exist = false;
        const userRoles = JSON.parse(localStorage.getItem('role'))||[];
        roles.forEach(role=>{
            userRoles.forEach(r=>{
                if(r===role){
                    exist=true;
                    return exist;
                }
            });
            if(exist) return exist;
        });
        return exist;
    }
    const app = {
        messages,
        addMessage,
        user,
        storeToken,
        logout,
        isAuthorized,
        setConfirmationMessage,
        setOpenConfirmationMessage
    }
    return (
        <ThemeProvider theme={theme}>                
            <Router>
                <AppContext.Provider value={app}>    
                    <CssBaseline/>
                    <div className={classes.root} >
                        {app.messages.map((message,key)=>(
                            <Message key={key} message={message.content} type={message.type} />
                        ))
                        }
                        {confirmationMessage &&
                            <ConfirmationDialog title={confirmationMessage.title} content={confirmationMessage.content} 
                                open={openConfirmationMessage} 
                                valider={confirmationMessage.valider} 
                                annuler={()=>setOpenConfirmationMessage(false)}
                            />
                        } 
                        {user ?
                            <React.Fragment>
                                <MenuLateral />
                                <AppRoute /> 
                            </React.Fragment>
                            :
                            <AppRouteAuth />
                        }
                    </div>
                </AppContext.Provider>
            </Router>
        </ThemeProvider>
    );
 }
 
 
