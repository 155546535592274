import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import MaterialTable from 'material-table';
import {MTableToolbar} from 'material-table';

import { AppContext } from '../../App';

// import validate from '../../validate';
import parametres from '../../config/parameters';
import util from '../../util';
import IconMaterialTable from '../IconMaterialTable';
import Filtre from './Filtre';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
    iconButton: {
        padding: 10,
        marginTop:20,
        color:'green'
	},
}))


export default function ListeGeolocalise(props){
    const classes = useStyles();
    // eslint-disable-next-line
    const [contribuables,setContribuables] = React.useState([]);
    const [services,setServices] = React.useState([]);
    const [users,setUsers] = React.useState([]);
    const [filtre,setFiltre] = React.useState("");
    const [query,setQuery] = React.useState({});
    const refTable = React.useRef(null);
    const appContext = React.useContext(AppContext);
    const history = useHistory();
    const colonnes = [
        { title: 'Date Géo', field: 'date_geo',
            render: rowData => moment(rowData.date_geo).format('DD/MM/YYYY HH:mm:ss'),
            editable:'never' },
        { title: 'NIF', field: 'nif', editable: 'never' },
        { title: 'Raison', field: 'raisonsociale' },
        { title: 'Telephone', field: 'telephone_sigfig' },
        { title: 'Commune', field: 'libelle_commune' },
        { title: 'Quartier', field: 'libelle_quartier'},
        { title: 'Activite', field: 'libelle_activite_principale'},
        { title: 'Service', field: 'service_gestion'},
        { title: 'Longitude', field: 'longitude'},
        { title: 'Latitude', field: 'latitude'},
        { title: 'Agent', field: 'username'},
    ];
    React.useEffect(()=>{
       fetchData();
    },[])
    
    const fetchData = async ()=>{
        const [services,users] = await Promise.all([
            await axios.get(parametres.API_URL+'/parameters/services-gestions',{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
            }),  
            await axios.get(parametres.API_URL+'/parameters/usernames',{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
            }),     
        ]) 
        setServices(services.data);
        setUsers(users.data);
    }

    const fetchContribuables = async (query)=>{
        query.filtre = filtre;
        setQuery(query);
        try {
            const res = await axios.get(parametres.API_URL+'/contribuables/geolocalises',{
                    headers:{'authorization':'Bearer '+localStorage.getItem('token')},
                    params:{...query}
                })      
            setContribuables(res.data);
            return {
                data:res.data.liste,
                page:query.page,
                totalCount:parseInt(res.data.count)
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }

    return (
        <Container className={classes.root}>
                <Typography variant="h4" color="inherit" align="center" className={classes.title}>
                        Liste des contribuables non géolocalisés
                </Typography>
                <MaterialTable
                    title=""
                    icons={IconMaterialTable}
                    columns={colonnes}
                    data={fetchContribuables}
                    tableRef={refTable}   
                    options={{
                        debounceInterval:1000,
                        exportButton: true,
                        exportAllData:true,
                        exportDelimiter:";",
                        exportFileName:'non_geolocalises_'+moment().format("DDMMYYYYHHmmss"),
                        pageSize:20,
                        pageSizeOptions:[20,50,100],
                        exportCsv: async (columns, data) => {
                            const q = {search:query.search,filtre:filtre};
                            try {
                                const res = await axios.get(parametres.API_URL+'/contribuables/geolocalises',{
                                    headers:{'authorization':'Bearer '+localStorage.getItem('token')},
                                    params:{...q}
                                });
                                const values = res.data.liste;
                                util.exportCsv('non_geolocalises_'+moment().format("DDMMYYYYHHmmss")+'.csv',columns,values)
                            } catch (error) {
                                console.log(error,error.response);
                                appContext.addMessage(util.getContentError(error),'error');
                            }
                        }
                    }}
                    actions={[
                        {
                            icon:'assignment',
                            tooltip:'Fiche contribuable',
                            onClick:(e,rowData)=>history.push('contribuable/'+rowData.nif)
                        }
                    ]}                                 
                    components={{
                        Toolbar: (props) => (
                          <div>
                            <MTableToolbar 
                                {...props} 
                            />
                                <div style={{padding: '0px 10px'}}>
                                    <Filtre 
                                        services={services} 
                                        users={users}
                                        filtre={filtre}
                                        setFiltre={setFiltre}
                                        onSearchChanged={props.onSearchChanged}
                                    />
                                </div>
                          </div>
                        ),
                      }}                     
                    
                />
        </Container>
    )
};

