import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import Map from '@material-ui/icons/Map';
import Dashboard from '@material-ui/icons/Dashboard';
import Sync from '@material-ui/icons/Sync';
import WifiOff from '@material-ui/icons/WifiOff';
import Wifi from '@material-ui/icons/Wifi';
import Check from '@material-ui/icons/Check';
import Cancel from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SkipNext from '@material-ui/icons/SkipNext';
import AccountBalance from '@material-ui/icons/AccountBalance';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import SkipPrevious from '@material-ui/icons/SkipPrevious';
import {Link} from 'react-router-dom';

import { AppContext } from '../App';

const styles = theme => ({
  categoryHeader: {
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
  },
  categoryHeaderPrimary: {
	color: theme.palette.common.primary,
	fontSize: 20,
  },
  item: {
	paddingTop: 1,
	paddingBottom: 1,
	/*color: 'rgba(255, 255, 255, 0.7)',
	'&:hover,&:focus': {
	  backgroundColor: 'rgba(255, 255, 255, 0.08)',
	},*/
  },
  itemCategory: {
	// backgroundColor: '#232f3e',
	boxShadow: '0 -1px 0 #404854 inset',
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
  },
  title: {
	fontSize: 24,
	color: theme.palette.common.black,
  },
  itemActiveItem: {
	color: '#4fc3f7',
  },
  itemPrimary: {
	fontSize: 'inherit',
  },
  itemIcon: {
	minWidth: 'auto',
	marginRight: theme.spacing(2),
  },
  divider: {
	marginTop: theme.spacing(2),
  },
});

const menus = [
	{
	  id: 'Accueil',
	  roles:["VISITEUR","USER","ADMIN","BIG","SUPERVISEUR"],
	  children: [
		{ id: 'Tableau de bord', icon: <Dashboard style={{color:'#64b5f6'}} />, path:'/',roles:["VISITEUR","USER","ADMIN"] },
		{ id: 'Carte', icon: <Map style={{color:'#64b5f6'}} />,path:'/carte',roles:["VISITEUR","USER","ADMIN","BIG","SUPERVISEUR"]},
	  ],
	},
	{
		id: 'Operations',
		roles:["USER","ADMIN","BIG","SUPERVISEUR"],
		children: [
		  { id: 'En attente', icon: <Sync style={{color:'#64b5f6'}} />, path:'/operations-en-attente',roles:["SUPERVISEUR","ADMIN","BIG"] },
		  { id: 'Validées', icon: <Check style={{color:'green'}} />,path:'/operations-validees',roles:["SUPERVISEUR","ADMIN","BIG"]},
		  { id: 'Rejetées', icon: <Cancel style={{color:'red'}} />,path:'/operations-rejetees',roles:["SUPERVISEUR","ADMIN","BIG"]},
		  { id: 'Géolocalisées', icon: <Wifi style={{color:'green'}} />,path:'/contribuables-geolocalises',roles:["SUPERVISEUR","ADMIN","BIG"]},
		  { id: 'Non géolocalisées', icon: <WifiOff style={{color:'yellow'}} />,path:'/contribuables-non-geolocalises',roles:["SUPERVISEUR","ADMIN","BIG"]},
		  { id: 'Modification', icon: <Edit style={{color:'#64b5f6'}} />,path:'/modification-operations-validees',roles:["ADMIN"]},
		  { id: 'Sync Manuelle', icon: <Sync style={{color:'green'}} />,path:'/sync-manuel-contribuable',roles:["SUPERVISEUR","ADMIN"]},
		],
	  },
	{
	  id: 'APIP',
	  roles:["APIP","ADMIN"],
	  children: [
		{ id: 'Dossiers reçus', icon: <SkipNext style={{color:'green'}} />, path:'/apip-dossiers-recus',roles:["APIP","ADMIN"] },
		{ id: 'NIFs envoyés', icon: <SkipPrevious style={{color:'#64b5f6'}}/>, path:'/apip-nifs-envoyes',roles:["APIP","ADMIN"]},
	  ],
	},
	{
	  id: 'DOUANE',
	  roles:["DGD","ADMIN"],
	  children: [
		{ id: 'NIF PRO -> DGD', icon: <AccountBalance style={{color:'green'}} />, path:'/dgd-transfert-pro',roles:["PRO","DGD","ADMIN"] },
		{ id: 'NIF IO -> DGD', icon: <DirectionsCar style={{color:'green'}} />, path:'/dgd-transfert-io',roles:["IO","DGD","ADMIN"] },
		{ id: 'Reçus de la DGD', icon: <SkipPrevious style={{color:'#64b5f6'}}/>, path:'/dgd-recu',roles:["DGD","ADMIN"]},
	  ],
	},
	{
	  id: 'App',
	  roles:["APIP","DGD","USER","ADMIN","SUPERVISEUR"],
	  children: [
		{ id: 'Deconnexion', icon: <ExitToApp style={{color:'red'}} />, path:'/logout',roles:["USER","ADMIN","APIP","DGD","SUPERVISEUR"] },
		{ id: 'Utilisateur', icon: <PeopleIcon style={{color:'#64b5f6'}}/>, path:'/users',roles:["ADMIN"]},
	  ],
	},
  ];
function Navigator(props) {
	const { classes, ...other } = props;
	const appContext = React.useContext(AppContext);
  return (
	<Drawer variant="permanent" {...other} >
	  <List disablePadding className={classes.itemCategory}>
		<ListItem className={clsx(classes.title, classes.item, classes.itemCategory)}>
		   NIFP
		</ListItem>
		
		{menus.map(({ id,roles, children }) => ( appContext.isAuthorized(roles) &&
		  <React.Fragment key={id}>
		  <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
			{children.map(({ id: childId, icon, active,path,roles }) => ( appContext.isAuthorized(roles) &&
			  <ListItem
				key={childId}
				button
				className={clsx(classes.item, active && classes.itemActiveItem)}
				component={Link}
				to={path}
			  >
				<ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
				<ListItemText
				  classes={{
					primary: classes.itemPrimary,
				  }}
				>
				  {childId}
				</ListItemText>
			  </ListItem>
			))}
			<Divider className={classes.divider} />
		  </React.Fragment>
		))}
	  </List>
	</Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
