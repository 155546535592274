import React from 'react';

import { AppContext } from '../../App';


export default function Logout(){
    const appContext = React.useContext(AppContext);
    React.useEffect(()=>{
        appContext.logout();
        
        // eslint-disable-next-line
    },[]);
    return (
       <React.Fragment>

       </React.Fragment>
    )
};