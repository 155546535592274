import React,{ useEffect, useState,useRef } from "react";

import { makeStyles } from '@material-ui/core/styles';
import {Container,Grid,Paper,InputBase,IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';

import axios from 'axios';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconRed from './images/marker-icon-red.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import util from "../../util";
// import util from '../../util';

import { AppContext } from '../../App';

import parametres from '../../config/parameters';



let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow 
});

let IconRed = L.icon({
    iconUrl: iconRed,
    shadowUrl: iconShadow 
});




const useStyles = makeStyles(theme => ({
    root: {
		flexDirection:'column'
	},
	gridSearch:{

	},
	paper: {
		padding: '2px 4px',
		margin:'2px',
		display: 'flex',
		alignItems: 'center',
		width: 400,
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
}))

export default function Carte(props){
	const [operationsFiltrees,setOperationsFiltrees] = useState([]);
	const [operationsEnAttentesFiltrees,setOperationsEnAttentesFiltrees] = useState([]);
	const [operations,setOperations] = useState([]);
	const [operationsEnAttentes,setOperationsEnAttentes] = useState([]);
	const [searchKey,setSearchKey] = useState("");
	const [center, setCenter] = useState([9.546942952246887,-13.65978240966797]);
	let refMap = useRef(null);
	const appContext = React.useContext(AppContext);
	let refLayer = useRef(null);

	const classes = useStyles();

	useEffect(() => {
		// create map
		refMap.current = L.map('map', {
			center,
			zoom: 12,
			layers: [
				L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				// L.tileLayer(`${parametres.APP_MAPS_URL}/hot/{z}/{x}/{y}.png`, {
				attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
				}),
			]
			});
		refLayer.current = L.layerGroup().addTo(refMap.current);
		refMap.current.on('click',e=>console.log(e));
		fetchOperations();
		// eslint-disable-next-line
	  }, []);


	useEffect(() => {
		if(searchKey==="" || !searchKey) return;
		let ops = operations.filter(op=> 
			op.nif.includes(searchKey.toUpperCase()) || 
			op.raisonsociale.toUpperCase().includes(searchKey.toUpperCase())
		);
		setOperationsFiltrees(ops)
		ops = operationsEnAttentes.filter(op=> 
			op.nif.includes(searchKey.toUpperCase()) || 
			op.raisonsociale.toUpperCase().includes(searchKey.toUpperCase())
		);
		setOperationsEnAttentesFiltrees(ops)
		// eslint-disable-next-line
	}, [searchKey]);

	useEffect(()=>{
		setOperationsFiltrees(operations);
	},[operations]);

	useEffect(()=>{
		setOperationsEnAttentesFiltrees(operationsEnAttentes);
	},[operationsEnAttentes]);

	useEffect(()=>{
		refLayer.current.clearLayers();
		if(operationsFiltrees.length!==0){
			setCenter(util.centrePoints(operationsFiltrees));
		}
		operationsFiltrees.forEach(op=>{
			L.marker([op.latitude.replace(/ /g,""),op.longitude.replace(/ /g,"")],{
				title:`${op.nif}-${op.raisonsociale}`,
				icon:DefaultIcon
			}).addTo(refLayer.current)
			.bindPopup(`${op.nif}-${op.raisonsociale}`);
		});
	},[operationsFiltrees])

	useEffect(()=>{
		// refLayer.current.clearLayers();
		operationsEnAttentesFiltrees.forEach(op=>{
			L.marker([op.latitude.replace(/ /g,""),op.longitude.replace(/ /g,"")],{
				title:`${op.nif}-${op.raisonsociale}`,
				icon:IconRed
			}).addTo(refLayer.current)
			.bindPopup(`${op.nif}-${op.raisonsociale}`);
		});
	},[operationsEnAttentesFiltrees])

	const fetchOperations = async () => {
		try {
            const [validees,enAttentes] = await Promise.all([
				axios.get(parametres.API_URL+'/operations/validees',{
					headers:{'authorization':'Bearer '+localStorage.getItem('token')} 
				}),
				axios.get(parametres.API_URL+'/operations',{
					headers:{'authorization':'Bearer '+localStorage.getItem('token')} 
				}),
			]) 
			setOperations(validees.data.liste);
			setOperationsEnAttentes(enAttentes.data.liste);
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
	};

	const handleSearch = (e)=>{
		setSearchKey(e.target.value);
	}
	return (
		<Grid container direction="column" justify="flex-start" alignItems="flex-end" >
			<Grid item style={{margin:'5px'}} >
				<Paper className={classes.paper}>
					<InputBase
						name="searchKey"
						className={classes.input}
						placeholder="Rechercher"
						inputProps={{ 'aria-label': 'rechercher' }}
						onChange={handleSearch}
						value={searchKey}
					/>
					<IconButton className={classes.iconButton} aria-label="search">
						<SearchIcon />
					</IconButton>
				</Paper>
			</Grid>
			<Grid container item xs>
				<Container maxWidth="md">
					<div id="map" style={{position:'relative',height:'100%'}}></div>
				</Container >
			</Grid>
		</Grid>
	)
}
