import React from 'react'
import { useParams, useHistory } from 'react-router-dom';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,Paper, Grid,Typography} from '@material-ui/core';
import axios from 'axios';
import ReactJson from 'react-json-view'

import { AppContext } from '../../App';

import parametres from '../../config/parameters';
import util from '../../util';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%',
        padding: theme.spacing(3)
    },
    gridItem:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(0.2)
    },
    title : {
        textAlign:'center'
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function Detail(){
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    const [dossier,setDossier] = React.useState({});
    // const [checked,setChecked] = React.useState(false);
    const history = useHistory();
    let {id} = useParams();
    const fetchDossier = async ()=>{
        try {
            const {data} = await axios.get(parametres.API_URL+'/dgd/transfert/'+id,{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
            });
            if(!data){
                appContext.addMessage('nif introuvable','error');
                history.push('/');
            }
            setDossier(data)

        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    React.useEffect(()=>{
        fetchDossier()

        // eslint-disable-next-line
    },[]);


    return (
        <Container className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
            <Typography className={classes.title} variant="h4" color="textSecondary" gutterBottom>
                DETAIL
            </Typography>
                <Grid container>
                    <ReactJson src={dossier} 
                        name="Contribuable" 
                        displayDataTypes={false}
                        displayObjectSize={false}
                        onEdit={false}
                        onAdd={false}
                        onDelete={false}
                    />
                </Grid>
            </Paper>
        </Container>
    )
}