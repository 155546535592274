import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';

// import validate from '../../validate';
import parametres from '../../config/parameters';
import util from '../../util';
import IconMaterialTable from '../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function ListeValidees(){
    const classes = useStyles();
    // eslint-disable-next-line
    const [operations,setOperations] = React.useState([]);
    const [query,setQuery] = React.useState({});
    const refTableOperation = React.useRef(null);
    const appContext = React.useContext(AppContext);
    const history = useHistory();
    const colonnes = [
        { title: 'Date', field: 'date_validation_format',type:'date',editable:'never' },
        { title: 'Agent Saisie', field: 'username' },
        { title: 'Agent Validation', field: 'username_validation' },
        { title: 'NIF', field: 'nif', editable: 'never' },
        { title: 'Raison', field: 'raisonsociale' },
        { title: 'Quartier', field: 'libelle_quartier' },
        { title: 'Commune', field: 'libelle_commune'},
        { title: 'Sectur', field: 'libelle_secteur'},
        { title: 'Longitude', field: 'longitude'},
        { title: 'Latitude', field: 'latitude'},
    ];
    

    const fetchOperations = async (query)=>{
        setQuery(query);
        try {
            const res = await axios.get(parametres.API_URL+'/operations/validees',{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
                params:{...query}
            });
            setOperations(res.data);
            return {
                data:res.data.liste,
                page:query.page,
                totalCount:parseInt(res.data.count)
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }

    return (
        <Container className={classes.root}>
                <Typography variant="h4" color="inherit" align="center" className={classes.title}>
                        Liste des operations validées
                </Typography>
                <MaterialTable
                    title=""
                    icons={IconMaterialTable}
                    columns={colonnes}
                    data={fetchOperations}
                    tableRef={refTableOperation}
                    options={{
                        debounceInterval:1000,
                        exportButton: true,
                        exportAllData:true,
                        exportDelimiter:";",
                        exportFileName:'valides_'+moment().format("DDMMYYYYHHmmss"),
                        pageSize:20,
                        pageSizeOptions:[20,50,100],
                        exportCsv: async (columns, data) => {
                            const q = {search:query.search};
                            try {
                                const res = await axios.get(parametres.API_URL+'/operations/validees',{
                                    headers:{'authorization':'Bearer '+localStorage.getItem('token')},
                                    params:{...q}
                                });
                                const values = res.data.liste;
                                util.exportCsv('valides_'+moment().format("DDMMYYYYHHmmss")+'.csv',columns,values)
                            } catch (error) {
                                console.log(error,error.response);
                                appContext.addMessage(util.getContentError(error),'error');
                            }
                        }
                    }}
                    actions={[
                        {
                            icon:'assignment',
                            tooltip:'Fiche contribuable',
                            onClick:(e,rowData)=>history.push('contribuable/'+rowData.nif)
                        }
                    ]}                     
                    
                />
        </Container>
    )
};

