import React from 'react';

import axios from 'axios';
import parametres from '../../config/parameters';
import { Card,CardContent } from '@material-ui/core';
// import {AppContext} from '../App';
import CanvasJSReact from '../../assets/canvasjs.react';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function ChartParService (){
	const [data,setData]=React.useState([]);
	React.useEffect(()=>{
		fetchStatistique();
	},[])
	const fetchStatistique = async ()=>{
		const res = await axios.get(parametres.API_URL+'/statistiques/equipes',{
            headers:{'authorization':'Bearer '+localStorage.getItem('token')},
		}); 
		let stat = res.data.map(s=>({label:s.username,y:parseInt(s.nbre),indexLabel:s.nbre}));
		setData(stat);
	}
	const options = {
		animationEnabled: true,
		exportEnabled: true,
		theme: "light2", //"light1", "dark1", "dark2"
		title:{
			text: "Géolocalisation par equipe",
			fontSize:20,
			margin:2,
			horizontalAlign: "left",
		},
		data: [{
			type: "column", //change type to bar, line, area, pie, etc
			//indexLabel: "{y}", //Shows y value on all Data Points
			indexLabelFontColor: "#5A5757",
			indexLabelPlacement: "outside",
			dataPoints: data
		}]
	}
	return (
		<Card>
			<CardContent>
				<CanvasJSChart options = {options} />
			</CardContent>
		</Card>
	)
 }         
 