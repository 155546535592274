import React from 'react';
import {FormControl,InputLabel,Select,MenuItem,Checkbox,ListItemText,Input} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },  }));

export default function SelectRoles({roles,rolesSelected,onChange}){
    const classes = useStyles();
    // const [rolesSelected,setRolesSelected] = React.useState([]);
    const handleChange = event => {
        onChange(event.target.value);
    };
    return(
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">Roles</InputLabel>
            <Select
                multiple
                value={rolesSelected||[]}
                onChange={handleChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={selected =>selected && selected.join(', ')}
            // MenuProps={MenuProps}
            >
            {roles && roles.map(r => (
                <MenuItem key={r} value={r}>
                <Checkbox checked={rolesSelected && rolesSelected.indexOf(r) > -1} />
                <ListItemText primary={r} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}