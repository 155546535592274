import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {InputLabel,FormControl,Select,TextField, Paper,Button} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles(theme => ({
    root: {
        justifyContent:'space-between',
        alignContent:'center'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
}))
export default function Filtre({services,users,filtre,setFiltre,...props}){
    const classes = useStyles();
    const [fields,setFields]=React.useState({});

    const handleChange = (e)=>{
        setFields({...fields,[e.target.name]:e.target.value});
        setFiltre({...filtre,[e.target.name]:e.target.value});
    }
    const isDirty = ()=> {
        let dirty=false;
        if(!filtre) return false;
        Object.keys(filtre).forEach(k=>{
            if(filtre[[k]]!=="") dirty=true
        });
        return dirty;
    }
    return (
        <Paper className={classes.root}>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">Service</InputLabel>
                <Select
                    native
                    value={filtre.service}
                    onChange={handleChange}
                    inputProps={{
                        name: 'service',
                    }}
                >
                    <option value="" />
                    {services && services.map((s,key)=>(
                        <option key={key} value={s}>{s}</option>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">Equipe</InputLabel>
                <Select
                    native
                    value={filtre.equipe}
                    onChange={handleChange}
                    inputProps={{
                        name: 'equipe',
                    }}
                >
                    <option value="" />
                    {users && users.map((s,key)=>(
                        <option key={key} value={s.username}>{s.username}</option>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField
                    id="date"
                    label="Date debut géo"
                    value={filtre.dateDebut}
                    type="date"
                    onChange={handleChange}
                    // className={classes.textField}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        name: 'dateDebut',
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField
                    id="date"
                    label="Date fin géo"
                    type="date"
                    value={filtre.dateFin}
                    onChange={handleChange}
                    // className={classes.textField}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        name: 'dateFin',
                    }}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<SearchIcon />}
                    onClick={()=>props.onSearchChanged("")}
                    disabled={!isDirty()}
                >
                    Filtrer
                </Button>
            </FormControl>
            
        </Paper>
    
    )
}