import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import { useHistory } from 'react-router-dom';


// import moment from 'moment';
import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';

// import validate from '../../validate';
import parametres from '../../config/parameters';
import util from '../../util';
import IconMaterialTable from '../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function Liste(){
    const classes = useStyles();
    // eslint-disable-next-line
    const [operations,setOperations] = React.useState([]);
    const refTableOperation = React.useRef(null);
    const appContext = React.useContext(AppContext);
    const history = useHistory();
    const colonnes = [
        { title: 'Date', field: 'date_operation_format',type:'date',editable:'never' },
        { title: 'Agent', field: 'username',editable:'never' },
        { title: 'NIF', field: 'nif', editable: 'never' },
        { title: 'Raison', field: 'raisonsociale',editable:'never' },
        { title: 'Quartier', field: 'libelle_quartier',editable:'never' },
        { title: 'Commune', field: 'libelle_commune',editable:'never'},
        { title: 'Sectur', field: 'libelle_secteur',editable:'never'},
        { title: 'Longitude', field: 'longitude'},
        { title: 'Latitude', field: 'latitude'},
    ];
    

    const fetchOperations = async (query)=>{
        try {
            const res = await axios.get(parametres.API_URL+'/operations',{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
                params:{...query}
            });
            setOperations(res.data);
            return {
                data:res.data.liste,
                page:query.page,
                totalCount:parseInt(res.data.count)
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    const rowUpdate = async (newData,oldData)=>{
        console.log(newData,oldData);
        try {
            await axios.put(parametres.API_URL+'/operations/correction-coordonnees/'+oldData.id,
            {operation:newData},
            {headers:{'authorization':'Bearer '+localStorage.getItem('token')}}
            );
            appContext.addMessage('Modification effectuée avec succès','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    return (
        <Container className={classes.root}>
                <Typography variant="h4" color="inherit" align="center" className={classes.title}>
                        Liste des operations non validées
                </Typography>
                <MaterialTable
                    title=""
                    icons={IconMaterialTable}
                    columns={colonnes}
                    data={fetchOperations}
                    tableRef={refTableOperation}
                    editable={{
                        onRowUpdate:rowUpdate
                    }}
                    actions={[{
                        icon:'check',
                        tooltip:'Valider',
                        iconProps:{color:'primary'},
                        onClick:(e,data)=>{
                            //afficher une boite de confirmation
                            appContext.setConfirmationMessage({
                                content:'Voulez-vous valider cette operation',
                                title:'Confirmation',
                                valider: async ()=>{
                                    try {
                                        const res = await axios.post(parametres.API_URL+'/operations/validation/'+data.id,{},{
                                            headers:{'authorization':'Bearer '+localStorage.getItem('token')}
                                        });
                                        appContext.addMessage(res.data,'success');
                                        //refresh de lapage
                                        refTableOperation.current && refTableOperation.current.onQueryChange()
                                    } catch (error) {
                                        console.log(error,error.response);
                                        appContext.addMessage(util.getContentError(error),'error');
                                    }
                                    appContext.setConfirmationMessage(null);
                                }
                            })
                        }        
                    },{
                        icon:'cancel',
                        tooltip:'Rejeter',
                        iconProps:{color:'error'},
                        onClick:(e,data)=>{
                            //afficher une boite de confirmation
                            appContext.setConfirmationMessage({
                                content:'Voulez-vous rejeter cette operation',
                                title:'Confirmation',
                                valider: async()=>{
                                    try {
                                        const res = await axios.post(parametres.API_URL+'/operations/rejet/'+data.id,{},{
                                            headers:{'authorization':'Bearer '+localStorage.getItem('token')}
                                        });
                                        appContext.addMessage(res.data,'success');
                                        //refresh de lapage
                                        refTableOperation.current && refTableOperation.current.onQueryChange()
                                    } catch (error) {
                                        console.log(error,error.response);
                                        appContext.addMessage(util.getContentError(error),'error');
                                    }
                                    appContext.setConfirmationMessage(null);
                                }
                            })
                        }        
                    },
                    {
                        icon:'assignment',
                        tooltip:'Fiche contribuable',
                        onClick:(e,rowData)=>history.push('contribuable/'+rowData.nif)
                    }
                ]}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize:20,
                        pageSizeOptions:[20,50,100],
                        debounceInterval:1000,
                    }}
                    
                    
                />
        </Container>
    )
};

