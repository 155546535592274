import React from 'react'
import { useParams, useHistory } from 'react-router-dom';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,Paper, Grid, TextField, Typography, Checkbox,FormControlLabel} from '@material-ui/core';
import axios from 'axios';

import { AppContext } from '../../App';

import parametres from '../../config/parameters';
import util from '../../util';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%',
        padding: theme.spacing(3)
    },
    gridItem:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(0.2)
    },
    title : {
        textAlign:'center'
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function Fiche(){
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    const [contribuable,setContribuable] = React.useState({});
    const [checked,setChecked] = React.useState(false);
    const history = useHistory();
    let {nif} = useParams();
    const fetchContribuable = async ()=>{
        try {
            const {data} = await axios.get(parametres.API_URL+'/contribuables/fiche-contribuable/'+nif,{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
            });
            if(!data){
                appContext.addMessage('Contribuable introuvable','error');
                history.push('/');
            }
            setContribuable(data)

        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }

    React.useEffect(()=>{
        fetchContribuable()

        // eslint-disable-next-line
    },[]);

    React.useEffect(()=>{
        setChecked(contribuable.flag_transfert_sigfig)
    },[contribuable.flag_transfert_sigfig])
    return (
        <Container className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
            <Typography className={classes.title} variant="h4" color="textSecondary" gutterBottom>
                FICHE CONTRIBUABLE
            </Typography>
                <Grid container>
                    <Grid item xs={2} className={classes.gridItem}>
                        <TextField
                            disabled
                            label="NIF"
                            value={contribuable.nif||''}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="RAISON SOCIALE"
                                fullWidth
                                value={contribuable.raisonsociale||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="TYPE ENTREPRISE"
                                fullWidth
                                value={contribuable.libelle_type_entreprise||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="GROUPE D'ACTIVITE"
                                fullWidth
                                value={contribuable.libelle_groupe_activite||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="ACTIVITE PRINCIPALE"
                                fullWidth
                                value={contribuable.libelle_activite_principale||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="COMMUNE"
                                fullWidth
                                value={contribuable.libelle_commune||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="QUATIER"
                                fullWidth
                                value={contribuable.libelle_quartier||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="SECTEUR"
                                fullWidth
                                value={contribuable.libelle_secteur||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={5} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="RESPONSABLE"
                                fullWidth
                                value={`${contribuable.prenomresponsable||''} ${contribuable.nomresponsable||''}`}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="TELEPHONE SIGFIG"
                                fullWidth
                                value={contribuable.telephone_sigfig||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="TELEPHONE 1"
                                fullWidth
                                value={contribuable.telephone1||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="TELEPHONE 2"
                                fullWidth
                                value={contribuable.telephone2||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="LATITUDE"
                                fullWidth
                                value={contribuable.latitude||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={2} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="LONGITUDE"
                                fullWidth
                                value={contribuable.longitude||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={7} className={classes.gridItem}>
                        <TextField
                                disabled
                                label="DESCRIPTION"
                                multiline
                                fullWidth
                                value={contribuable.observation||''}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <TextField
                            disabled
                            label="AGENT GEOLOCALISATEUR"
                            multiline
                            fullWidth
                            value={`${contribuable.prenom_agent||''} ${contribuable.nom_agent||''}`}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.gridItem} style={{marginLeft:10}}>
                        {contribuable &&
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        label="Transferé dans SIGFIG"
                                        checked={checked}
                                        value={''}
                                        disabled
                                    />
                                    }
                                label="COORDONNEES TRANSFERES DANS SIGFIG"
                            />
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}