import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
// import moment from 'moment';
import axios from 'axios';

import { AppContext } from '../../App';

// import validate from '../../validate';
import parametres from '../../config/parameters';
import util from '../../util';

const useStyles = makeStyles(theme => ({
    root: {
        width: '800px',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    form:{
        marginTop: theme.spacing(5)
    },
    paper:{
        width:'80%',
        height:'200px',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        marginTop: theme.spacing(10)
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function Liste(){
    const classes = useStyles();
    // eslint-disable-next-line
    const appContext = React.useContext(AppContext);
    const [nif,setNif] = React.useState(null)
    const [text,setText] = React.useState({})
    const history = useHistory();

    

    const syncManuel = async ()=>{
        try {
            const res = await axios.post(parametres.API_URL+'/operations/sync-manuel-sigfig/'+nif,{},{
                headers:{'authorization':'Bearer '+localStorage.getItem('token')},
            });
            setText(res.data)
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }

    return (
        <Container className={classes.root}>
                <Typography variant="h4" color="inherit" align="center" className={classes.title}>
                        Récuperation manuelle contribuable
                </Typography>
                <Paper className={classes.paper} >
                    <form className={classes.form} noValidate autoComplete="off">
                        <TextField
                            id="outlined-secondary"
                            label="NIF"
                            // variant="outlined"
                            color="secondary"
                            helperText={text.content}
                            FormHelperTextProps={{
                                style:{
                                    color:text.type==='success'?'green':'red'
                                }
                            }}
                            onChange={e=>setNif(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<Icon>send</Icon>}
                            onClick={syncManuel}
                            disabled={!nif}
                        >
                            Recupérer
                        </Button>
                    </form>
                </Paper>
        </Container>
    )
};

