export default {
    login:{
        username:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        password:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        
    }
}